import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { ZiggyVue } from 'ziggy-js';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import * as Sentry from "@sentry/vue";
import 'vue3-toastify/dist/index.css';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue');
        return pages[`./Pages/${name}.vue`]();
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        Sentry.init({
                app,
                dsn: import.meta.env.SENTRY_DSN,
                environment: import.meta.env.APP_ENV,
            });

        app
            .use(plugin)
            .use(ZiggyVue)
            .use(Vue3Toastify, {
                autoClose: 3000,
            } as ToastContainerOptions)
            .mount(el);
        return app
    },
    progress: {
        color: '#4B5563',
    },
});
